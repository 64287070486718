.App {
  background-color: #f4f4f4;
  min-height: 100vh;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

nav {
  background-color: #333;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  display: flex; 
  justify-content: left; 
  align-items: center;
}

nav a {
  color: white;
  text-decoration: none;
  padding: 10px;
  margin-right: 10px;
}

nav a:hover {
  text-decoration: underline;
}

.icon {
  /* float: left;  */
  height: 50px;
  width: 100px;
  margin-right: 10px;
}

.navButton {
  margin-right: 100px;
}

.navImage {
  height: 50px;
  width: 100px;
  object-fit: contain;
}


/* PROJECT PAGE */

.projects-page {
  padding-bottom: 50px;
}

.projectCard {
  /* background-color: #333; */
  height: 380px;
  width: 400px;
  text-align: center;
  display: flex;
  justify-content:space-between;
  flex-direction:column;
  border: 1px solid #333;
}

.cardImage {
  height: 250px;
  width: 400px;
  /* background-color: blue; */
  object-fit: cover;
}

.fitCardImage {
  height: 250px;
  width: 400px;
  /* background-color: blue; */
  object-fit: contain;
  background-color: rgb(233, 233, 233);;
}

.smallCardImageContainer {
  height: 250px;
  width: 400px;
  background-color: rgb(233, 233, 233);
  display: flex;
  align-items: center;
  justify-content: center;

}

.smallCardImage {
  height: 120px;
  width: 120px;
  border: 1.5px solid #333;
  border-radius: 33px;
}

.cardsContainer {
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, max-content));
  grid-gap: 16px;
  justify-content: center;
  padding: initial;
}

.cardInfoContainer {
  position: relative;
}

.winnerRibbon {
  position: absolute;
  top:0;
  left: 0;
  height: 60px;
  width: 60px;
}

.cardTitle {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* background-color: green; */
  text-align: left;
}

.cardDesc {
  /* background-color: yellow; */
  margin-left: 10px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.cardButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cardButton {
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  background-color: rgb(245, 168, 46);
  text-decoration: none;
  padding: 5px;
  border: 1px solid #333;
}
.cardButton:hover {
  background-color: rgb(166, 115, 32);
}

.cardButton:disabled {
  background-color: gray;
}

.pageTitle {
  margin-left: 20px;
}
.projectSectionTitle {
  margin-left: 26px;
}

/* HOME PAGE */
/* Container for the whole page */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Introduction section */
.intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}


.triangle {
  /* background-color: green; */
  height: 100%;
  width: 500px;
  display: flex;
}
.trianglediv {
  border-width: 250px 0px 250px 500px;
  position: absolute;
  border-style: solid;
  border-color: transparent transparent transparent rgb(245, 168, 46);
}

.pp-border-container {
  z-index: 5;
  width: 100%;
  /* background-color: blue; */
  display: flex;
  /* justify-content: center; */
}

.pp-image-container {
  width: 300px;
  height: 300px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: 75px;
}

.pp-image-container img {
  max-width: 100%;
  border-radius: 150px;
  height: auto;
  border-width: 4px;
  border-style: solid;
  border-color: white;
}

.intro-text {
  width: 500px;
  padding-left: 20px;
  /* background-color: red; */
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -250px;
  z-index: 10;
}

.intro-text * {
  font-size: 36px;
}

/* More content section */
.more-content {
  text-align: center;
  /* background-color: red; */
  height: 100px;
}

.more-content p {
  font-size: 18px;
  margin-bottom: 30px;
}

.action-buttons a {
  color: white;
  background-color: rgb(245, 168, 46);
  text-decoration: none;
  padding: 10px;
  margin-right: 50px;
  border: 1px solid white;
}

.action-buttons a:hover {
  background-color: rgb(166, 115, 32);
}

.social-icons {
  margin-top: 5px;
  display: flex;
  /* background-color: red; */
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.social-icons a {
  margin-right: 10px; /* Adjust spacing between icons */
}

.social-icons img {
  width: 40px; /* Adjust the width of the icons */
  height: auto; /* Maintain aspect ratio */
}

.hiddenText {
  color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* CAREER PAGE */
.pdfContainer {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 180px;
  height: 30px;
}

.resumeText {
  color: black;
}